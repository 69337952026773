import { Api } from '../';
import dayjs from 'dayjs';

/**
GET https://xapi.thg.dk/orders/Get?invoice_number=793641 (int)

GET https://xapi.thg.dk/orders/Get?order_number=AB123456789 (string) 

GET https://xapi.thg.dk/orders/Get?web_order_number=123456789 (int)

https://xapi.thg.dk/orders/GetHistory?start_date=01-01-2020&end_date=01-09-2020&limit=200&start=0 

https://xapi.thg.dk/orders/GetOrderTotals/AllPerYear

  status: 'created|active|finished|canceled|deleted|editing|rejected'

 */

/**
 *
 * Refinements string array of "license_plate, vehicle_id, item_number, invoice_number, order_number"
 * @param {*} params
 */
export function getHistory(params) {
  const defaultParams = {
    start_date: '01-01-2020', // TODO: Default to 1y back
    end_date: dayjs().format('DD-MM-YYYY'),
    start: 0,
    //refinements: ['order_number:104566748'],
  };

  return new Promise((resolve, reject) => {
    Api.post(`/orders/Query`, {
      ...defaultParams,
      ...params,
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getOrder(token) {
  return new Promise((resolve, reject) => {
    Api.get(`/orders/${token}`)
      .then(response => {
        const order = response.data.data ?? response.data;

        order.type = 'order';
        if (order.status === 'credited') {
          order.type = 'credit_note';
        } else if (order.invoice_number) {
          order.type = 'invoice';
        }

        resolve(order);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getAnonymousOrder(token) {
  return new Promise((resolve, reject) => {
    Api.get(`/orders/${token}/GetAnonymous`)
      .then(response => {
        if (!response.data) {
          reject({ error: 'not found' });
        }

        const order = response.data.data ?? response.data;
        order.type = 'order';
        if (order.status === 'credited') {
          order.type = 'credit_note';
        } else if (order.invoice_number) {
          order.type = 'invoice';
        }

        resolve(order);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getOrders(params) {
  return new Promise((resolve, reject) => {
    Api.post(`/orders/Query`, params)
      .then(response => {
        if (response.data && response.data.data) {
          resolve(response.data.data);
        } else {
          resolve(response.data && response.data.data ? response.data.data : []);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getEvents(token) {
  return new Promise((resolve, reject) => {
    Api.get(`/orders/${token}/Events`)
      .then(response => {
        const events = response.data.data ?? response.data;
        resolve(events);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getLocation(token) {
  return new Promise((resolve, reject) => {
    Api.get(`/orders/${token}/Location`)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postTransfer(data) {
  const defaultParams = {};
  return new Promise((resolve, reject) => {
    Api.post(`/orders/transfer`, data)
      .then(response => {
        if (response.data.errors) reject(response.data.errors);
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
