<template>
  <div class="OrderDeliveryEvent">
    <template v-if="event.details && event.type === OrderEventType.DELIVERY">
      <template v-if="isDelivered">
        <ColonListItem
          v-if="event.source !== 'collector'"
          :label="$t('order.delivery.delivered_about')"
          :value="event.date"
          :format="FormatTypes.DATE"
          date-format="LT"
          highlighted
          :grey="completed"
        />
        <ColonListItem
          v-if="deliveryAdress"
          :label="$t('order.delivery.delivered_to')"
          :value="deliveryAdress.name"
          highlighted
          :grey="completed"
        />
      </template>
      <template v-else>
        <ColonListItem
          v-if="specialDeliveryDeparture && specialDeliveryDeparture.estimated_arrival"
          :label="$t('order.delivery.estimated_delivery')"
          :value="specialDeliveryDeparture.estimated_arrival"
          :format="FormatTypes.DATE"
          date-format="LT"
          highlighted
          :grey="completed"
        />
        <ColonListItem
          v-if="deliveryAdress && !isDelivered"
          :label="$t('order.delivery.delivered_to')"
          :value="deliveryAdress.name"
          highlighted
          :grey="completed"
        />
        <ColonListItem
          v-if="!isDelivered && departureDepartment"
          :label="$t('order.delivery.departure_from')"
          :value="departureDepartment.name"
          highlighted
          :grey="completed"
        />
      </template>

      <template v-if="specialDeliveryDeparture">
        <ColonListItem
          :label="$t('special_delivery.id')"
          :value="specialDeliveryDeparture.id"
          highlighted
          :grey="completed"
        />
      </template>

      <OrderDeliveryMap v-if="showMap" class="OrderDeliveryEvent__map" :markers="deliveryMarkers" />
      <CollectorLink v-if="event.source === 'collector'" :grey="completed" />
    </template>
  </div>
</template>

<script>
import { Modal } from '@components/Modal';

import ColonListItem from '@scenes/SelfserviceScene/components/ColonListItem';
import CollectorLink from './components/CollectorLink';
import { OrderEventType, OrderEventSubType, OrderEventActionType } from '@types/Order';
import { FormatTypes } from '@scenes/SelfserviceScene/components/ColonListItem';

export default {
  name: 'OrderDeliveryEvent',

  components: {
    OrderDeliveryMap: () => import('./components/OrderDeliveryMap'),
    Modal,
    ColonListItem,
    CollectorLink,
  },

  enums: {
    OrderEventType,
    FormatTypes,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showMap() {
      return (
        this.event.sub_type === OrderEventSubType.SPECIAL_DELIVERY &&
        this.event.source === 'collector' &&
        this.event.index === 0 &&
        (this.event.action_type === OrderEventActionType.NEXT_STOP ||
          this.event.action_type === OrderEventActionType.IN_TRANSIT)
      );
    },
    deliveryMarkers() {
      return {
        departure:
          !this.event.details.vehicle_location && // Only show if no vehicle position is available
          this.event.details.departure_department &&
          this.event.details.departure_department.coordinates,
        vehicle:
          this.event.details.vehicle_location && this.event.details.vehicle_location.coordinates,
        customer:
          this.event.details.delivery_address && this.event.details.delivery_address.coordinates,
      };
    },
    deliveryAdress() {
      return (
        this.event.details.delivery_address &&
        this.event.details.delivery_address.name &&
        this.event.details.delivery_address
      );
    },
    departureDepartment() {
      return (
        this.event.details.departure_department &&
        this.event.details.departure_department.name &&
        this.event.details.departure_department
      );
    },
    specialDeliveryDeparture() {
      return this.event.details.special_delivery_departure;
    },
    isDelivered() {
      return this.event.action_type === OrderEventActionType.DELIVERED;
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderDeliveryEvent {
  .OrderDeliveryEvent__map {
    width: 100%;
    margin: 0.8em 0;
    padding-bottom: 59%;
    max-width: 350px;
  }
}
</style>
