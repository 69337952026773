var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DeliveryExtension',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
return [_c('div',{staticClass:"OrderHead"},[_c('div',{staticClass:"OrderHead__main"},[_c('div',{staticClass:"OrderHead__events"},[(_vm.events && _vm.events.length > 0)?_c('Timeline',{attrs:{"events":_vm.events,"show-full-date":""},scopedSlots:_vm._u([{key:"eventContent",fn:function(ref){
var event = ref.event;
var completed = ref.completed;
return [(event.type === 'delivery')?_c('OrderDeliveryEvent',{attrs:{"event":event,"completed":completed}}):_vm._e()]}}],null,true)}):_vm._e()],1)]),_c('div',{staticClass:"OrderHead__sidebar"},[_c('ContactCard'),(_vm.orderContainer.order)?_c('Details',{staticClass:"OrderHead__details",attrs:{"data":_vm.details}}):_vm._e(),(
          _vm.$isEnabled(_vm.Features.QR_SCANNERS) &&
            _vm.orderContainer.order.delivery.type === 'pick_up' &&
            status === _vm.PickupStatus.READY
        )?_c('QRCard',{attrs:{"src":"//cdn.thg.dk/DAT/dom/img/QR_Scanner-Transparent_Background_Text_new.png","color":_vm.Colors.GREY,"value":'O' + _vm.orderContainer.order.order_number,"large":""}}):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }