import { OrderPickupStatus as Status } from '@types/Order';
import { isBeforeNow } from '@helpers/date';
export default {
  name: 'DeliveryExtension',

  computed: {
    delivery() {
      return this.orderContainer.order.delivery;
    },
    isDispatched() {
      // Is picked up or shipped.
      return this.orderContainer.eventTypes.includes(2);
    },
    status() {
      if (this.availableFrom && !isBeforeNow(this.availableFrom)) {
        return Status.NOT_READY;
      }
      if (this.orderContainer.eventTypes.includes(2)) return Status.PICKED_UP;
      if (
        this.orderContainer.eventTypes.includes(5) ||
        this.orderContainer.eventTypes.includes(6)
      ) {
        return Status.READY;
      }
      return Status.NOT_READY;
    },
    pickupPoint() {
      return (
        this.orderContainer.order.delivery?.details?.pickup_point ||
        this.orderContainer.order.delivery?.details?.branch
      );
    },
    branch() {
      return this.orderContainer.order.delivery?.details?.branch;
    },
    availableFrom() {
      return this.orderContainer.order.delivery?.details?.pickup_available_from;
    },
    isClickAndCollect() {
      return this.orderContainer.order.delivery?.details?.is_click_and_collect;
    },
  },

  render() {
    return this.$scopedSlots.default({
      status: this.status,
      branch: this.branch,
      availableFrom: this.availableFrom,
      isDispatched: this.isDispatched,
      pickupPoint: this.pickupPoint,
      isClickAndCollect: this.isClickAndCollect,
    });
  },

  provide() {
    return {
      DeliveryContainer: this,
    };
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing orderContainer');
        return;
      },
    },
  },
};
