<template>
  <div class="ContactInfo">
    <div v-if="name">{{ name }}</div>
    <Address class="ContactInfo__address" :data="address" showCountry :forceWrap="forceWrap" />
    <div v-if="email">
      <a :href="`mailto:${email}`">{{ email }}</a>
    </div>
  </div>
</template>

<script>
import Address from '@components/Address';

export default {
  name: 'ContactInfo',

  components: {
    Address,
  },

  props: {
    address: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    forceWrap: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactInfo {
  .ContactInfo__address {
    margin-bottom: 0.3em;
  }
}
</style>
