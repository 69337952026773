<template>
  <DeliveryExtension v-slot="{ status }">
    <div class="OrderHead">
      <div class="OrderHead__main">
        <div class="OrderHead__events">
          <Timeline v-if="events && events.length > 0" :events="events" show-full-date>
            <template #eventContent="{ event, completed }">
              <OrderDeliveryEvent
                v-if="event.type === 'delivery'"
                :event="event"
                :completed="completed"
              />
            </template>
          </Timeline>
        </div>
      </div>

      <div class="OrderHead__sidebar">
        <ContactCard />
        <Details v-if="orderContainer.order" :data="details" class="OrderHead__details" />
        <QRCard
          v-if="
            $isEnabled(Features.QR_SCANNERS) &&
              orderContainer.order.delivery.type === 'pick_up' &&
              status === PickupStatus.READY
          "
          src="//cdn.thg.dk/DAT/dom/img/QR_Scanner-Transparent_Background_Text_new.png"
          :color="Colors.GREY"
          :value="'O' + orderContainer.order.order_number"
          large
        />
      </div>
    </div>
  </DeliveryExtension>
</template>

<script>
import Timeline from '@scenes/SelfserviceScene/components/Timeline';
import ContactCard from './components/ContactCard';
import Details, { Formats } from '@scenes/SelfserviceScene/components/Details';
import { OrderEventType, OrderEventActionType, OrderOrigin, OrderStatus } from '@types/Order';
import OrderDeliveryEvent from './components/OrderDeliveryEvent';
import OrderStatusLabel from '@scenes/SelfserviceScene/components/OrderStatusLabel';
import QRCard, { Colors } from '@scenes/SelfserviceScene/components/QRCard';

import Features from '@types/Features';
import { AccountType } from '@types/Account';
import { OrderPickupStatus as PickupStatus } from '@types/Order';

import { DeliveryExtension } from '@scenes/SelfserviceScene/containers/OrderContainer';

export default {
  name: 'OrderHead',

  components: {
    Timeline,
    ContactCard,
    Details,
    OrderDeliveryEvent,
    QRCard,
    DeliveryExtension,
  },

  enums: {
    Colors,
    PickupStatus,
    Features,
  },

  computed: {
    events() {
      return this.orderContainer.order.events.map(event => {
        let icon = '';
        if (event.type === OrderEventType.DELIVERY) {
          if (event.action_type === OrderEventActionType.UNLOADING) {
            icon = 'fas fa-truck-loading';
          } else if (event.action_type === OrderEventActionType.DELIVERED) {
            icon = 'fas fa-check';
          } else {
            icon = 'fas fa-truck';
          }
        }
        return { ...event, icon };
      });
    },
    showDelivery() {
      return (
        this.orderContainer.order.status !== OrderStatus.CANCELED &&
        this.orderContainer.order.origin !== OrderOrigin.PHONE &&
        this.orderContainer.order.origin !== OrderOrigin.SHOP &&
        this.orderContainer.order.origin !== OrderOrigin.CREDIT_NOTE
      );
    },
    details() {
      return [
        this.orderContainer.accountType === AccountType.DEALER && {
          header: this.$t('order.account_number'),
          label: this.orderContainer.order.account_number,
        },
        {
          header: this.$t('order.status'),
          template: OrderStatusLabel,
          templateProps: { solid: true, status: this.orderContainer.order.status },
        },
        {
          header: this.$t('order.order_number'),
          label: this.orderContainer.order.order_number,
        },
        {
          header: this.$t('order.invoice_number'),
          label: this.orderContainer.order.invoice_number,
        },
        {
          header: this.$t('order.invoice_created'),
          label: this.orderContainer.order.invoice_created,
          format: Formats.DATE,
        },
        {
          header: this.$t('order.created'),
          label: this.orderContainer.order.created,
          format: Formats.DATE,
        },
        /*{
          header: this.$t('order.origin'),
          label: this.orderContainer.order.origin
            ? this.$t('order.origin.' + this.orderContainer.order.origin)
            : '',
        },*/
        {
          header: this.$t('order.payment_term'),
          label: this.orderContainer.order.payment_term,
        },
        this.showDelivery && {
          header: this.$t('order.delivery.delivery_type'),
          label: this.orderContainer.order.delivery.name,
        },
        /*this.orderContainer.order?.delivery?.details?.branch && {
          header: this.$t('order.delivery.pickup.location'),
          template: BranchCardSmall,
          templateProps: { branch: this.orderContainer.order.delivery.details.branch },
          wide: true,
        },*/
        this.$isEnabled(Features.ACCOUNT_DELIVERY) &&
          this.orderContainer.order.latest_departure && {
            header: this.$t('special_delivery.departure'),
            label: this.orderContainer.order.latest_departure,
            format: Formats.DATE,
          },
        this.orderContainer.deliveryType === 'shipping' && {
          header: this.$t('order.weight'),
          label: this.orderContainer.order.weight
            ? Number(this.orderContainer.order.weight).toLocaleString() + ' kg'
            : '',
        },
        this.orderContainer.order.requisition && {
          header: this.$t('order.requisition'),
          label: this.orderContainer.order.requisition,
        },
        this.orderContainer.order.customer_reference && {
          header: this.$t('order.customer_reference'),
          label: this.orderContainer.order.customer_reference,
        },
        this.orderContainer.order.worksheet_number && {
          header: this.$t('order.worksheet_number'),
          label: this.orderContainer.order.system_name
            ? `${this.orderContainer.order.worksheet_number} (${this.orderContainer.order.system_name})`
            : this.orderContainer.order.worksheet_number,
        },
      ];
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderHead {
  display: flex;
  @include maxBreakpoint(768) {
    flex-direction: column;
  }
}

.OrderHead__main {
  flex: 1;
}

.OrderHead__sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.OrderHead__details {
  padding: 20px;
  background-color: $color-prim-grey-lighter;
  border-radius: $border-radius;
}

.OrderData {
  display: flex;
  flex-wrap: wrap;
}

.OrderData__item {
  flex-basis: 100%;

  @media screen AND (min-width: $mobileL) {
    flex-basis: 50%;

    &:nth-child(odd) {
      padding-right: 10px;
    }
    &:nth-child(even) {
      padding-left: 10px;
    }
  }
}

.OrderData__item-header {
  display: block;
  color: $color-prim-grey-dark;
  text-transform: uppercase;
  font-weight: bold;
}

.OrderHead__events {
  margin-bottom: 40px;

  @media screen AND (min-width: $tablet) {
    margin-bottom: 0;
  }
}
</style>
