<template>
  <div v-if="orderContainer.order && orderContainer.order.references" class="Alerts">
    <template v-for="(reference, index) in orderContainer.order.references">
      <Alert
        v-if="orderContainer.order.origin === OrderOrigin.FORWARD"
        :key="index"
        :level="AlertLevel.WARNING"
        hideIcon
      >
        Denne ordre er en restordre af den oprindelige ordre:
        <router-link
          :to="{ name: '/selfservice/orders/:id', params: { id: reference.data.token } }"
        >
          {{ reference.data.order_number }}
        </router-link>
      </Alert>
      <Alert
        v-else-if="reference.type === OrderOrigin.FORWARD"
        :key="index"
        :level="AlertLevel.WARNING"
        hideIcon
      >
        Nogle varer på ordren, er i restordre - se restordre
        <router-link
          :to="{ name: '/selfservice/orders/:id', params: { id: reference.data.token } }"
        >
          {{ reference.data.order_number }}
        </router-link>
      </Alert>
    </template>
  </div>
</template>

<script>
import Alert, { AlertLevel } from '@components/Alert';
import { OrderOrigin } from '@types/Order';

export default {
  name: 'Alerts',

  components: {
    Alert,
  },

  enums: {
    AlertLevel,
    OrderOrigin,
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Alerts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}
</style>
