<template>
  <div class="LiveStatus"><i class="LiveStatus__icon fas fa-circle"></i> Opdateres automatisk</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
.LiveStatus {
  font-size: 12px;
  color: grey;
  .LiveStatus__icon {
    font-size: 0.5em;
    color: #c92f2f;
    vertical-align: middle;
    margin-right: 0.3em;
    animation: pulse 2s infinite;
  }
}
</style>
