<template>
  <OrderContainer
    ref="orderContainer"
    v-slot="{ order, status, autoRefreshActive }"
    auto-refresh
    update-title
    :token="$route.params.id"
    :anonymous="anonymous"
  >
    <SkeletonTransition :ready="status !== OrderState.LOADING && status !== OrderState.UPDATING">
      <template #default>
        <div class="OrderScene">
          <RmaContainer ref="rmaContainer">
            <Modal ref="returnModal" :title="$t('order.return')" :size="ModalSizes.LARGE">
              <template #default>
                <RmaLinePicker
                  :lookup="{ type: 'invoiceNumber', query: order.invoice_number.toString() }"
                  add-found-lines
                />
              </template>
              <template #controls v-if="$isEnabled(Features.ACCOUNT_RETURNS)">
                <Button type="cta" @click="submitDraftLines">
                  {{ $t('order.return.create_return') }}
                </Button>
              </template>
            </Modal>
          </RmaContainer>

          <div v-if="status === OrderState.SUCCESS">
            <div class="OrderHead">
              <PageBar
                class="PageBar"
                :controls="[
                  order.download_link && {
                    name: getOrderTypeControlsTranslation(order.type),
                    download: order.download_link,
                    icon: buttonIcons.DOWNLOAD,
                  },
                  !isMobileSize && {
                    name: $t('order.controls.print'),
                    action: printPage,
                    icon: buttonIcons.PRINT,
                  },
                  $isEnabled(Features.ACCOUNT_RETURNS) &&
                    !anonymous &&
                    order.has_returnable_items && {
                      name: $t('order.return.return_products'),
                      color: buttonColors.DEFAULT,
                      action: () => $refs.returnModal.open(),
                      icon: buttonIcons.RETURN,
                    },
                ]"
              >
              </PageBar>
              <div class="OrderHead__header">
                <h1 class="OrderHead__heading ft-h2">
                  {{ $t('order.type.order') }} {{ order.order_number }}
                </h1>
                <LiveStatus v-if="autoRefreshActive" class="OrderHead__status" />
              </div>
              <template
                v-if="
                  order.delivery && !order.ignore_delivery && order.status !== OrderStatus.CANCELED
                "
              >
                <PickupBanner v-if="order.delivery.type === 'pick_up'" />
                <ShippingBanner v-else-if="order.delivery.type === 'shipping'" />
              </template>
              <OrderHead />
              <Alerts />
            </div>

            <OrderLines />

            <div class="OrderScene__footer">
              <div class="AmountSummary">
                <div class="AmountSummary__line">
                  <div>{{ $t('price.subtotal') }}</div>
                  <div><Price :value="order.net_amount" /></div>
                </div>
                <div v-if="order.freight_rate" class="AmountSummary__line">
                  <div>{{ $t('price.freight') }}</div>
                  <div><Price :value="order.freight_rate" /></div>
                </div>
                <div class="AmountSummary__line">
                  <div>{{ $t('price.vat') }}</div>
                  <div><Price :value="order.vat_amount" /></div>
                </div>
                <div class="AmountSummary__line">
                  <div>{{ $t('price.total') }}</div>
                  <div><Price :value="order.amount" /></div>
                </div>
              </div>
              <div
                v-if="
                  order.account_type === AccountType.DEALER &&
                    order.address &&
                    order.address.zip_code
                "
                class="OrderScene__contactInfo"
              >
                <h5 class="ft-h5">{{ $t('order.invoice_address') }}</h5>
                <ContactInfo
                  :address="order.address"
                  :name="order.address.name"
                  :email="order.address.email_address"
                  forceWrap
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #skeleton>
        <div class="OrderScene__skeleton">
          <div class="OrderScene__skeletonPagebar">
            <ButtonSkeleton />
          </div>
          <div class="OrderScene__skeletonOrderHead">
            <SkeletonGraphic :width="230" :height="30" />
            <div class="OrderScene__skeletonOrderHeadContent">
              <div class="OrderScene__skeletonTimeLine">
                <TimelineSkeleton :nodes="3" />
              </div>
              <DetailsSkeleton :items="6" class="OrderScene__skeletonDetailsSkeleton" />
            </div>
          </div>
          <div v-if="!isMobileSize" class="OrderScene__skeletonOrders">
            <TableSkeleton :rows="2" />
          </div>
          <div v-if="!isMobileSize" class="OrderScene__skeletonSummary">
            <div class="OrderScene__skeletonSummaryLabels">
              <SkeletonGraphic :width="66" :height="22" />
              <SkeletonGraphic :width="52" :height="22" />
              <SkeletonGraphic :width="42" :height="22" />
            </div>
            <div class="OrderScene__skeletonSummaryValues">
              <SkeletonGraphic :width="73" :height="22" />
              <SkeletonGraphic :width="55" :height="22" />
              <SkeletonGraphic :width="73" :height="22" />
            </div>
          </div>
        </div>
      </template>
    </SkeletonTransition>
  </OrderContainer>
</template>

<script>
import { AccountType } from '@types/Account';
import PickupBanner from './components/PickupBanner';
import ShippingBanner from './components/ShippingBanner';
import OrderContainer from '@scenes/SelfserviceScene/containers/OrderContainer';
import {
  SkeletonGraphic,
  SkeletonTransition,
  ButtonSkeleton,
  TimelineSkeleton,
  DetailsSkeleton,
  TableSkeleton,
} from '@components/Skeleton';
import { Modal, Sizes as ModalSizes } from '@components/Modal';
import { Button, colors as buttonColors, icons as buttonIcons } from '@components/Buttons';
import Price from '@components/Price';
import RmaContainer from '@scenes/SelfserviceScene/containers/RmaContainer';
import RmaLinePicker from '@scenes/SelfserviceScene/components/RmaLinePicker';
import PageBar from '@scenes/SelfserviceScene/components/PageBar';
import LiveStatus from './components/LiveStatus';
import OrderHead from './components/OrderHead';
import Alerts from './components/Alerts';
import OrderLines from './components/OrderLines';
import ContactInfo from './components/ContactInfo';
import Features from '@types/Features';
import { OrderStatus, OrderState, Type as OrderType } from '@types/Order';

export default {
  name: 'OrderScene',

  components: {
    PickupBanner,
    ShippingBanner,
    OrderContainer,
    SkeletonGraphic,
    SkeletonTransition,
    ButtonSkeleton,
    TimelineSkeleton,
    DetailsSkeleton,
    TableSkeleton,
    Modal,
    Button,
    Price,
    RmaContainer,
    RmaLinePicker,
    PageBar,
    LiveStatus,
    OrderHead,
    Alerts,
    OrderLines,
    ContactInfo,
  },

  enums: {
    AccountType,
    ModalSizes,
    buttonColors,
    buttonIcons,
    Features,
    OrderState,
    OrderStatus,
  },

  computed: {
    isMobileSize() {
      return ['mobileS', 'mobileM', 'mobileL'].includes(this.$mq);
    },
  },

  created() {
    this.anonymous = this.$route.meta.anonymous;
  },

  methods: {
    printPage() {
      window.print();
    },
    submitDraftLines() {
      this.$refs.rmaContainer.createRma().then(rmaId => {
        this.$refs.rmaContainer
          .submitDraftLines(rmaId, 'ReturnOfGoods')
          .then(rma => {
            this.$router.push({ name: '/selfservice/returns/:id', params: { id: rmaId } });
          })
          .catch(() => {});
      });
    },

    getOrderTypeControlsTranslation(type) {
      switch (type) {
        case OrderType.INVOICE:
          return this.$t('order.controls.download_invoice');
        case OrderType.CREDIT_NOTE:
          return this.$t('order.controls.download_credit_note');
        default:
          return this.$t('order.controls.download_invoice');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderHead__subheading {
  color: #a8a8a8;
  font-size: 16px;
}

.OrderHead__header {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.OrderHead__boxes {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.OrderHead__box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 280px;
  border-radius: $border-radius;
  padding: 10px 20px;
  background-color: $color-prim-grey-lighter;
  font-size: 16px;
  font-weight: bold;
}

.OrderScene__footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
}

.OrderScene__contactInfo {
  width: 300px;
  background-color: $color-prim-grey-lighter;
  border-radius: $border-radius;
  padding: 20px;
}

@include maxBreakpoint($tablet-numeric) {
  .OrderScene__footer {
    flex-direction: column;
    align-items: flex-end;
  }
  .OrderScene__contactInfo {
    width: 100%;
  }
}

.AmountSummary {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media (max-width: $laptopSMax) {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.AmountSummary__line {
  display: flex;
  margin-bottom: 7px;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    font-size: 1.15em;

    &:first-child {
      width: 80px;
      padding-left: 7px;
    }

    &:last-child {
      width: 170px;
      text-align: right;
      font-weight: bold;
    }
  }
}

@mixin printStyles() {
  .OrderHead {
    .OrderHead__heading {
      margin-bottom: 0;
      font-size: 20px;
    }
    .OrderHead__subheading {
      font-size: 55px;
    }
    .OrderHead__status {
      display: none;
    }
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}

.OrderScene__skeleton {
  height: 150vh;

  @include maxBreakpoint($mobileL-numeric) {
    height: 100vh;
  }

  .OrderScene__skeletonPagebar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid $color-prim-grey;
    margin-bottom: 20px;

    @include maxBreakpoint($mobileL-numeric) {
      opacity: 0%;
      height: 21px;
    }
  }

  .OrderScene__skeletonOrderHead {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .OrderScene__skeletonShippingBanner {
    margin-top: -20px;
  }

  .OrderScene__skeletonOrderHeadContent {
    display: flex;
    @include maxBreakpoint($tablet-numeric) {
      flex-direction: column;
      gap: 70px;
    }
  }

  .OrderScene__skeletonTimeLine {
    flex: 1;
  }

  .OrderScene__skeletonDetailsSkeleton {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    flex: 1;
    row-gap: 20px;
  }

  .OrderScene__skeletonOrders {
    margin-top: 80px;
  }

  .OrderScene__skeletonSummary {
    display: flex;
    justify-content: flex-end;
    gap: 60px;
    margin-top: 80px;
    margin-right: 38px;
  }

  .OrderScene__skeletonSummaryLabels {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .OrderScene__skeletonSummaryValues {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
}
</style>
