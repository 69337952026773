import requestStatus from '@types/RequestStatus';

import Vue from 'vue';
export const PollingRate = {
  LOW: 'LOW',
  HIGH: 'HIGH',
};
export default {
  name: 'Requester',
  props: {
    method: {
      type: Function,
      required: true,
    },
    param: {
      type: [Object, Array, String, Number],
      default: null,
    },
    polling: {
      type: Boolean,
      default: false,
    },
    pollingRate: {
      type: String,
      default: PollingRate.LOW,
    },
    pollingMaxRetries: {
      type: Number,
      default: 5,
    },
    compareResponses: {
      type: [Boolean, Function],
      default: false,
    },
    autoStart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: '',
      response: null,
      pollingRetries: 0,
      pollingInterval: null,
      pollingMaxCount: 2000,
      pollingCount: 0,
    };
  },
  watch: {
    polling: {
      handler(active) {
        if (active && this.autoStart) {
          this.startPolling();
        } else {
          this.stopPolling();
        }
      },
      immediate: false,
    },
    isPolling: {
      handler(active) {
        this.$emit('is-polling-change', active);
      },
      immediate: true,
    },
    autoStart: {
      handler(active) {
        if (active) {
          //this.runRequest();
          this.startPolling();
        }
      },
      immediate: true,
    },
  },
  render() {
    return this.$scopedSlots.default({
      status: this.status,
      response: this.response,
      runRequest: this.runRequest,
      retry: this.retry,
      isPolling: this.isPolling,
    });
  },
  computed: {
    isPolling() {
      return this.pollingInterval !== null;
    },
    pollingDelay() {
      if (this.pollingRate === PollingRate.HIGH) {
        return 4000;
      }

      return 30000;
    },
  },

  methods: {
    retry() {
      this.pollingRetries = 0;
      this.pollingCount = 0;
      this.runRequest();

      this.startPolling();
    },
    clearPollingInterval() {
      clearTimeout(this.pollingInterval);
      this.pollingInterval = null;
    },
    startPolling() {
      this.clearPollingInterval();
      if (this.polling) {
        this.pollingInterval = setTimeout(this.runRequest, this.pollingDelay);
      }
    },
    stopPolling() {
      this.clearPollingInterval();
    },
    /** Run request */
    runRequest() {
      this.status = !this.status ? requestStatus.FETCHING : requestStatus.UPDATING;

      if (typeof this.method === 'function') {
        this.method(this.param)
          .then(this.onRequestSucces)
          .catch(this.onRequestError);
      }
      this.pollingCount += 1;
      if (this.pollingCount > this.pollingMaxCount) {
        this.stopPolling();
      } else {
        this.pollingInterval = setTimeout(this.runRequest, this.pollingDelay);
      }
    },
    setResponse(response, silent) {
      const oldResponse = this.response;
      this.response = response;
      if (!silent) {
        this.$emit('update', { response, oldResponse });
      }
    },
    onRequestSucces(response) {
      this.pollingRetries = 0;
      this.status = requestStatus.FETCHED;
      if (!this.response) {
        this.setResponse(response, true);
      } else if (!this.compareResponses) {
        this.setResponse(response);
      } else {
        if (
          this.compareResponses === true &&
          JSON.stringify(response) !== JSON.stringify(this.response)
        ) {
          this.setResponse(response);
        } else if (
          typeof this.compareResponses === 'function' &&
          !this.compareResponses(response, this.response)
        ) {
          this.setResponse(response);
        }
      }
    },
    onRequestError(error) {
      this.pollingRetries += 1;

      if (!this.polling || this.pollingRetries > this.pollingMaxRetries) {
        this.status = requestStatus.ERROR;
        // No more retries - action must happen!
      }
    },
    created() {
      if (this.autoStart) {
        this.runRequest();
      }
    },
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
