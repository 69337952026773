<template>
  <DeliveryExtension v-slot="{ isDispatched }">
    <div v-if="!isDispatched && visible" class="AvailabilityStatus">
      <i class="AvailabilityStatus__icon fas fa-info-circle"></i>
      <span
        >{{ $t('order.line.delivery_available') }} d. <Time :datetime="availableFrom" format="L"
      /></span>
    </div>
  </DeliveryExtension>
</template>

<script>
import Time from '@components/Time';
import { isBeforeNow } from '@helpers/date';
import { DeliveryExtension } from '@scenes/SelfserviceScene/containers/OrderContainer';

export default {
  name: 'AvailabilityStatus',
  components: {
    Time,
    DeliveryExtension,
  },
  computed: {
    availableFrom() {
      return this.line.available_from;
    },
    visible() {
      if (!this.availableFrom || this.orderContainer.order.lines.length <= 1) return false;
      return !isBeforeNow(this.availableFrom);
    },
    status() {
      return this.$t('order.line.not_in_stock');
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.AvailabilityStatus {
  background: #00529b;
  display: inline-block;
  color: white;
  padding: 0.2em 0.6em;
  .AvailabilityStatus__icon {
    margin-right: 0.2em;
  }
}
</style>
