<template>
  <div>
    <div :class="['CollectorLink', grey && 'CollectorLink--grey']">
      <svg
        class="CollectorLink__icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 48 48"
        xml:space="preserve"
      >
        <path :fill="colorTape" d="M11.16 26.38l-4.39-2.76v-6.27l4.55 2.95z" />
        <path :fill="colorTape" d="M26.97 10.35L11.32 20.3l-4.55-2.95 15.81-9.64z" />
        <path
          :fill="color"
          d="M46.13 41.15h-5.31v1.87H48v-7.18h-1.87zM24.85 35.84h-1.87v1.96L18.04 41V24.7l13.36-8.65v7.78h2.34V13.9v-.01-.01c0-.1-.02-.19-.04-.28v-.02c-.03-.1-.07-.19-.12-.28-.02-.03-.04-.05-.06-.08-.04-.05-.08-.11-.12-.16a.55.55 0 00-.09-.07c-.04-.03-.08-.07-.13-.1l-15.7-9.45c-.37-.22-.84-.22-1.21 0L.57 12.9c-.05.03-.08.07-.13.1-.03.03-.07.05-.09.07-.05.05-.09.1-.13.16-.02.03-.04.05-.06.08-.05.09-.09.18-.12.28v.01c-.02.09-.04.18-.04.28v19.11c0 .4.2.77.53.98l15.7 10.17c.01.01.03.01.04.02.01.01.02.02.04.03.05.03.1.04.15.06.03.01.06.03.1.04.1.03.2.04.31.04.1 0 .21-.02.31-.04.03-.01.06-.03.1-.04.05-.02.1-.03.15-.06.01-.01.02-.02.04-.03.01-.01.03-.01.04-.02l5.47-3.55v2.43h7.18v-1.87h-5.31v-5.31zM15.7 41L2.34 32.35v-16.3L15.7 24.7V41zm1.17-18.33L3.38 13.94l13.49-8.12 13.49 8.12-13.49 8.73z"
        />
        <path
          :fill="color"
          d="M22.98 31.01h1.87v-5.3h5.31v-1.88h-7.18zM40.82 23.83v1.88h5.31v5.3H48v-7.18zM28.25 28.87h1.56v9.12h-1.56zM30.83 28.87h1.56v9.12h-1.56zM33.42 28.87h1.56v9.12h-1.56zM36 28.87h1.56v9.12H36zM38.58 28.87h1.56v9.12h-1.56zM41.17 28.87h1.56v9.12h-1.56z"
        />
      </svg>
      <span>Collector app™</span>
    </div>
    <Modal ref="modal" title="Hvad er Collector app™?" :size="ModalSizes.LARGE">
      Mere information på vej.
    </Modal>
  </div>
</template>

<script>
import { Modal, Sizes as ModalSizes } from '@components/Modal';

export default {
  name: 'CollectorLink',

  components: {
    Modal,
  },

  enums: {
    ModalSizes,
  },

  props: {
    grey: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    color() {
      return this.grey ? '#bbbbbb' : '#000000';
    },
    colorTape() {
      return this.grey ? '#bbbbbb' : '#00529c';
    },
  },

  methods: {
    openModal() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.CollectorLink {
  color: $color-prim-brand-vau;
  font-weight: bold;
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 13px;

  &--grey {
    color: #bbbbbb;
  }

  &:hover {
    //filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    color: black;
  }

  .CollectorLink__icon {
    width: 28px;
    margin-right: 0.3em;
    //transition: transform 200ms ease-in-out;
  }
  &:hover .CollectorLink__icon {
    //transform: scale(1.03);
  }
}
</style>
