export const OrderState = {
  LOADING: 'LOADING',
  UPDATING: 'UPDATING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const OrderType = {
  WEB: 'Bestilt på web',
  STORE: 'Købt i butikken',
  PHONE: 'Telefon-bestilling',
};

export const Type = {
  INVOICE: 'invoice',
  ORDER: 'order',
  CREDIT_NOTE: 'credit_note',
};

export const OrderStatus = {
  CREATED: 'created',
  ACTIVE: 'active',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  DELETED: 'deleted',
  EDITING: 'editing',
  REJECTED: 'rejected',
  CREDITED: 'credited',
};

export const OrderOrigin = {
  PHONE: 'phone',
  FAX: 'fax',
  MAIL: 'mail',
  OTHERS: 'others',
  SHOP: 'shop',
  WEB: 'web',
  VOICE_RESPONSE: 'voice_response',
  EMAIL: 'e_mail',
  FORWARD: 'forward',
  COMPLAINT: 'complaint',
  SWAP: 'swap',
  CREDIT_NOTE: 'credit_note',
};

export const OrderPickupStatus = {
  NOT_READY: 'NOT_READY',
  READY: 'READY',
  PICKED_UP: 'PICKED_UP',
};

// Events
export const OrderEventType = {
  DELIVERY: 'delivery',
  UNKNOWN: 'unknown',
};

export const OrderEventSubType = {
  ACCOUNT_DELIVERY: 'special_delivery',
  NIGHT_DELIVERY: 'night_delivery',
};

export const OrderEventActionType = {
  DELIVERED: 'delivered',
  DISPATCHED: 'dispatched',
  UNLOADING: 'unloading',
  IN_TRANSIT: 'enroute',
  NEXT_STOP: 'next_stop',
  DISPATCH_ENDED: 'dispatch_ended',
};
