<template>
  <div>
    <div class="ShippingBanner">
      <div class="ShippingBanner__header">
        <h3 class="ShippingBanner__title ft-h4">
          {{ $t('order.delivery') }} ({{ delivery.name }})
        </h3>
        <ImageComponent
          v-if="delivery.image"
          :src="delivery.image"
          :alt="delivery.name"
          class="ShippingBanner__image"
        />
      </div>
      <Details :data="details" />
      <div class="ShippingBanner__footer">
        <template v-if="tracking && tracking.url">
          <Button
            v-if="isPostNord"
            :size="ButtonSizes.SMALL"
            :color="ButtonColors.WHITE"
            :icon="ButtonIcons.LINK"
            @click="$refs.modal.open()"
          >
            {{ $t('order.controls.track_delivery') }}
          </Button>
          <Button
            v-else-if="tracking"
            :size="ButtonSizes.SMALL"
            :color="ButtonColors.WHITE"
            :icon="ButtonIcons.LINK"
            tag="a"
            :href="tracking.url"
            target="_blank"
          >
            {{ $t('order.controls.track_delivery') }}
          </Button>
        </template>
        <!--<p v-else class="ShippingBanner__info ft-p2">
          <i class="ShippingBanner__icon fal fa-info-circle" />
          {{ $t('order.message.track_delivery_when_order_send') }}
        </p>-->
      </div>
    </div>
    <Modal ref="modal" v-if="tracking && tracking.url && isPostNord" :size="ModalSizes.LARGE">
      <PostNordWidget :shipmentId="tracking.number" />
    </Modal>
  </div>
</template>

<script>
import ImageComponent from '@components/ImageComponent';
import {
  Button,
  sizes as ButtonSizes,
  colors as ButtonColors,
  icons as ButtonIcons,
} from '@components/Buttons';
import { Modal, Sizes as ModalSizes } from '@components/Modal';
import Details, { Formats } from '@scenes/SelfserviceScene/components/Details';
import ContactInfo from '../ContactInfo';
import PostNordWidget from '../OrderHead/components/ShippingCard/components/PostNordWidget';

export default {
  name: 'ShippingBanner',

  components: {
    ImageComponent,
    Button,
    Modal,
    Details,
    PostNordWidget,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    ButtonIcons,
    ModalSizes,
  },

  computed: {
    address() {
      return this.orderContainer.order.address;
    },
    delivery() {
      return this.orderContainer.order.delivery;
    },
    pickupPoint() {
      return this.delivery.details?.pickup_point;
    },
    tracking() {
      return this.delivery.details?.trackings[0];
    },
    isPostNord() {
      return this.delivery.details?.courier === 'POSTNORD';
    },
    details() {
      return [
        this.address &&
          !this.pickupPoint && {
            header: this.$t('order.delivery.address'),
            template: ContactInfo,
            templateProps: {
              address: this.address,
              name: this.address.name,
              email: this.address.email_address,
            },
          },
        this.pickupPoint && {
          header: this.$t('order.delivery.pickup.address'),
          template: ContactInfo,
          templateProps: { address: this.pickupPoint.address, name: this.pickupPoint.name },
        },
        this.tracking && {
          header: this.$t('order.delivery.estimated_delivery'),
          label: this.tracking.estimated_delivery,
          format: Formats.DATE,
          dateFormat: 'LL',
        },
        this.tracking && {
          header: this.$t('order.tracking_number--short'),
          label: this.tracking.number,
        },
      ];
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ShippingBanner {
  display: flex;
  flex-direction: column;
  @include spacing-2('gap');
  width: 100%;
  background-color: $color-white;
  margin-top: -20px;
  margin-bottom: 40px;
  border: 1px solid $color-prim-grey;
  border-radius: 6px;
  padding: 20px 30px;

  .ShippingBanner__header {
    display: flex;
    justify-content: space-between;
  }

  .ShippingBanner__title {
    color: var(--color-prim);
  }

  .ShippingBanner__image {
    width: 110px;
    margin: -25px 0; // TODO: Remove when new trimmed logos are enabled
  }

  .ShippingBanner__footer {
    display: flex;
    justify-content: flex-end;
  }

  .ShippingBanner__info {
    font-weight: bold;
    color: $color-extra-info;
  }

  .ShippingBanner__icon {
    margin-right: 5px;
  }
}
</style>
