var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ResizeObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return [(width > 700)?_c('div',{staticClass:"OrderLines"},[_c('Table',{attrs:{"items":_vm.orderContainer.lines,"itemKey":"line_id","alignment":_vm.ColumnAlignment.RIGHT}},[_c('TableColumn',{attrs:{"header":_vm.$t('order.line.header'),"alignment":_vm.ColumnAlignment.LEFT},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('OrderLineCard',{attrs:{"line":item,"showVehicleId":_vm.orderContainer.accountType === _vm.AccountType.DEALER}},[_c('AvailabilityStatus',{staticClass:"OrderLineCard__availability",attrs:{"line":item}})],1)]}}],null,true)}),_c('TableColumn',{attrs:{"header":_vm.priceHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.product.item_type !== 'placeholder')?_c('Price',{attrs:{"value":item.product.price}}):_vm._e()]}}],null,true)}),_c('TableColumn',{attrs:{"header":_vm.$t('quantity'),"value":"quantity"}}),(_vm.includeNetPrice)?_c('TableColumn',{attrs:{"header":_vm.$t('price.net'),"value":"product.net_price","format":_vm.ColumnFormat.PRICE}}):_vm._e(),(_vm.includeDiscountRate)?_c('TableColumn',{attrs:{"header":_vm.$t('price.discount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.discount_rate && _vm.getPercentage(item.product.discount_rate))+" ")]}}],null,true)}):_vm._e(),_c('TableColumn',{attrs:{"header":_vm.$t('price.amount'),"strong":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.product.item_type !== 'placeholder')?_c('Price',{attrs:{"value":item.total}}):_vm._e()]}}],null,true)})],1)],1):_c('div',{staticClass:"OrderLines"},[_c('ul',{staticClass:"OrderLines__cards"},_vm._l((_vm.orderContainer.lines),function(line,index){return _c('OrderLineCard',{key:index,staticClass:"OrderLines__card",attrs:{"tag":"li","line":line}},[_c('AvailabilityStatus',{staticClass:"OrderLineCard__availability",attrs:{"line":line}}),_c('Details',{attrs:{"data":_vm.details(line),"columnSize":_vm.Sizes.TINY,"smallHeader":""}})],1)}),1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }