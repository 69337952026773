var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DeliveryExtension',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var branch = ref.branch;
var availableFrom = ref.availableFrom;
var isClickAndCollect = ref.isClickAndCollect;
return [_c('div',{class:[
      'PickupBanner',
      _vm.isMobileSize && 'PickupBanner--mobile',
      !branch && 'PickupBanner--spacing' ]},[_c('div',{staticClass:"PickupBanner__content"},[_c('div',{staticClass:"PickupBanner__header"},[(status === _vm.PickupStatus.PICKED_UP)?_c('div',{staticClass:"PickupBanner__title"},[_vm._v(" "+_vm._s(_vm.$t('order.delivery.pickup.collected'))+" ")]):(status === _vm.PickupStatus.NOT_READY)?_c('div',{staticClass:"PickupBanner__title PickupBanner__title--time "},[_vm._v(" "+_vm._s(_vm.$t('order.delivery.pickup.available_from'))+": "),(availableFrom)?_c('Time',{staticClass:"PickupBanner__pickupDate",attrs:{"datetime":availableFrom,"format":availableFrom.indexOf('00:00:0') !== -1 ? 'LL' : 'LLLL'}}):_vm._e()],1):(status === _vm.PickupStatus.READY)?_c('div',{staticClass:"PickupBanner__title"},[_vm._v(" "+_vm._s(_vm.$t('order.delivery.pickup.ready'))+" ")]):_vm._e(),_c('WorkshopTime'),(branch)?_c('BranchCardSmall',{staticClass:"PickupBanner__branch",attrs:{"branch":branch,"hideOpeningHours":status === _vm.PickupStatus.PICKED_UP,"date":availableFrom}}):_vm._e()],1),_c('div',{staticClass:"PickupBanner__progressBarContainer"},[_c('PickupTimeline',{staticClass:"PickupBanner__progressBar"})],1),(isClickAndCollect)?_c('div',{staticClass:"PickupBanner__deliveryImage"},[_c('ImageComponent',{attrs:{"src":"//cdn.thg.dk/dat/dom/img_misc/icon_delivery_click_and_collect_trim@2x.png","alt":_vm.orderContainer.order.delivery.name}})],1):_vm._e()])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }