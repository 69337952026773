<template>
  <StatusLabel :solid="solid" :type="labelType" v-if="!textOnly">
    <template #default>
      {{ statusMessage }}
    </template>
  </StatusLabel>
  <span v-else>{{ statusMessage }}</span>
</template>

<script>
import { OrderStatus } from '@types/Order';
import { StatusLabel, types as labelTypes } from '../StatusLabel';

export default {
  name: 'OrderStatusLabel',
  components: {
    StatusLabel,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    solid: {
      type: Boolean,
      default: false,
    },
    textOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelType() {
      switch (this.status) {
        case OrderStatus.ACTIVE:
          return labelTypes.SUCCESS;
          break;

        default:
          return labelTypes.TRACE;
          break;
      }
    },

    statusMessage() {
      return this.$t('selfservice.orderstatus.status.' + this.status);
    },
  },
};
</script>
