<template>
  <Requester
    v-slot="{ isPolling, retry }"
    :method="getEvents"
    :param="order && order.events_token"
    :polling="autoRefreshAllowed"
    :polling-rate="pollingRate"
    :auto-start="order && !order.complete"
    @update="onEventsResponses"
    ><slot
      name="default"
      :order="order"
      :status="status"
      :flatLines="flatLines"
      :autoRefreshActive="isPolling"
      :retryAutoRefresh="retry"
      :orginalOrder="orginalOrder"
      :navigateToOrder="navigateToOrder"
  /></Requester>
</template>

<script>
import Vue from 'vue';

import Requester, { PollingRate } from '@containers/Requester';

import { getOrder, getAnonymousOrder, getEvents } from '@services/thansen/orders';

import { OrderState } from '@types/Order';

export { default as DeliveryExtension } from './extensions/DeliveryExtension';

export default {
  name: 'OrderContainer',
  components: {
    Requester,
  },
  enums: {
    PollingRate,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    anonymous: {
      type: Boolean,
      default: false,
    },
    autoRefresh: {
      type: Boolean,
      default: false,
    },
    updateTitle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      order: null,
      status: '',
    };
  },

  computed: {
    pollingRate() {
      return this.order?.delivery?.type === 'special_delivery' ? PollingRate.HIGH : PollingRate.LOW;
    },
    autoRefreshAllowed() {
      return this.order !== null && !this.order.complete;
    },
    lines() {
      return this.order && this.order.lines !== undefined ? this.order.lines : [];
    },
    accountType() {
      return this.order?.account_type;
    },
    flatLines() {
      return this.order && this.order.lines !== undefined
        ? this.order.lines.map(line => ({
            id: line.line_id,

            image: line.product && line.product.image,
            item_number: line.product && line.product.item_number,
            title: line.product && line.product.title,
            license_plate: line.vehicle && line.vehicle.license_plate,
            car: line.vehicle && line.vehicle.description,
            departure_date:
              line.special_delivery_departure && line.special_delivery_departure.departed,
            product_net_price: line.product && line.product.net_price,
            product_price: line.product && line.product.price,
            discount_rate: line.product && line.product.discount_rate,
            quantity: line.quantity,
            total: line.total,
            data: line,
          }))
        : [];
    },
    orginalOrder() {
      if (this.order && this.order.references) {
        const originalOrder = this.order.references.find(reference => {
          return reference.type === 'original';
        });

        return originalOrder && originalOrder.data;
      }
      return null;
    },
    eventTypes() {
      if (this.order && this.order.events) {
        return this.order.events.map(event => {
          return event.type_id;
        });
      }
      return [];
    },
  },
  created() {
    this.fetchOrder();
    this.getEvents = getEvents;
  },
  destryed() {
    if (this.fetchDelay) clearTimeout(this.fetchDelay);
  },
  methods: {
    navigateToOrder(token) {
      this.$router.push({
        name: '/selfservice/orders/:id',
        params: { id: token },
      });
    },
    onEventsResponses({ response, oldResponse }) {
      Vue.set(this.order, 'events', response);
      if (
        response.length !== oldResponse.length ||
        response.some((event, index) => {
          return event.id !== oldResponse[index].id;
        })
      ) {
        this.fetchOrder();
      }
    },
    fetchOrder() {
      if (!this.order) {
        this.status = OrderState.LOADING;
      }

      if (this.anonymous) {
        getAnonymousOrder(this.token)
          .then(this.onOrderResponse)
          .catch(err => {
            this.status = OrderState.ERROR;
            console.error(err);
          });
      } else {
        getOrder(this.token)
          .then(this.onOrderResponse)
          .catch(err => {
            this.status = OrderState.ERROR;
            console.error(err);
          });
      }
    },
    onOrderResponse(response) {
      if (this.updateTitle) {
        if (response.order_number)
          document.title = `${this.$t('order')} - ${response.order_number}`;
        else document.title = `${this.$t('order')}`;
      }

      // Don't update the order if it is empty (no lines etc.) which can happen when the order is changing state (aktiv > faktura)
      if (
        !response ||
        (this.order?.lines && !response.lines) ||
        (this.order && !response.order_number)
      ) {
        this.delayedFetch();
      } else {
        this.order = response;
      }

      this.status = OrderState.SUCCESS;
    },
    delayedFetch() {
      // TODO: Max retry must be added.
      if (this.fetchDelay) clearTimeout(this.fetchDelay);
      this.fetchDelay = setTimeout(this.fetchOrder, 10000);
    },
  },

  provide() {
    return {
      orderContainer: this,
    };
  },
};
</script>

<style></style>
