<template>
  <vue-friendly-iframe
    :src="`/PostNordWidget.html?shipmentId=${this.shipmentId}&locale=${locale}`"
    className="PostNordWidget__iframe"
    class="PostNordWidget"
  />
</template>

<script>
import Vue from 'vue';
import VueFriendlyIframe from 'vue-friendly-iframe';

Vue.use(VueFriendlyIframe);

export default {
  name: 'PostNordWidget',

  props: {
    shipmentId: {
      type: String,
      required: true,
    },
  },

  computed: {
    locale() {
      switch (this.$config.LANGUAGE) {
        case 'da':
          return 'da';
        case 'nb':
          return 'no';
        default:
          return 'da';
      }
    },
  },
};
</script>

<style lang="scss">
.PostNordWidget__iframe {
  width: calc(100% - 2px);
  height: 580px;
}
</style>
