var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OrderContainer',{ref:"orderContainer",attrs:{"auto-refresh":"","update-title":"","token":_vm.$route.params.id,"anonymous":_vm.anonymous},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var order = ref.order;
var status = ref.status;
var autoRefreshActive = ref.autoRefreshActive;
return [_c('SkeletonTransition',{attrs:{"ready":status !== _vm.OrderState.LOADING && status !== _vm.OrderState.UPDATING},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"OrderScene"},[_c('RmaContainer',{ref:"rmaContainer"},[_c('Modal',{ref:"returnModal",attrs:{"title":_vm.$t('order.return'),"size":_vm.ModalSizes.LARGE},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('RmaLinePicker',{attrs:{"lookup":{ type: 'invoiceNumber', query: order.invoice_number.toString() },"add-found-lines":""}})]},proxy:true},(_vm.$isEnabled(_vm.Features.ACCOUNT_RETURNS))?{key:"controls",fn:function(){return [_c('Button',{attrs:{"type":"cta"},on:{"click":_vm.submitDraftLines}},[_vm._v(" "+_vm._s(_vm.$t('order.return.create_return'))+" ")])]},proxy:true}:null],null,true)})],1),(status === _vm.OrderState.SUCCESS)?_c('div',[_c('div',{staticClass:"OrderHead"},[_c('PageBar',{staticClass:"PageBar",attrs:{"controls":[
                order.download_link && {
                  name: _vm.getOrderTypeControlsTranslation(order.type),
                  download: order.download_link,
                  icon: _vm.buttonIcons.DOWNLOAD,
                },
                !_vm.isMobileSize && {
                  name: _vm.$t('order.controls.print'),
                  action: _vm.printPage,
                  icon: _vm.buttonIcons.PRINT,
                },
                _vm.$isEnabled(_vm.Features.ACCOUNT_RETURNS) &&
                  !_vm.anonymous &&
                  order.has_returnable_items && {
                    name: _vm.$t('order.return.return_products'),
                    color: _vm.buttonColors.DEFAULT,
                    action: function () { return _vm.$refs.returnModal.open(); },
                    icon: _vm.buttonIcons.RETURN,
                  } ]}}),_c('div',{staticClass:"OrderHead__header"},[_c('h1',{staticClass:"OrderHead__heading ft-h2"},[_vm._v(" "+_vm._s(_vm.$t('order.type.order'))+" "+_vm._s(order.order_number)+" ")]),(autoRefreshActive)?_c('LiveStatus',{staticClass:"OrderHead__status"}):_vm._e()],1),(
                order.delivery && !order.ignore_delivery && order.status !== _vm.OrderStatus.CANCELED
              )?[(order.delivery.type === 'pick_up')?_c('PickupBanner'):(order.delivery.type === 'shipping')?_c('ShippingBanner'):_vm._e()]:_vm._e(),_c('OrderHead'),_c('Alerts')],2),_c('OrderLines'),_c('div',{staticClass:"OrderScene__footer"},[_c('div',{staticClass:"AmountSummary"},[_c('div',{staticClass:"AmountSummary__line"},[_c('div',[_vm._v(_vm._s(_vm.$t('price.subtotal')))]),_c('div',[_c('Price',{attrs:{"value":order.net_amount}})],1)]),(order.freight_rate)?_c('div',{staticClass:"AmountSummary__line"},[_c('div',[_vm._v(_vm._s(_vm.$t('price.freight')))]),_c('div',[_c('Price',{attrs:{"value":order.freight_rate}})],1)]):_vm._e(),_c('div',{staticClass:"AmountSummary__line"},[_c('div',[_vm._v(_vm._s(_vm.$t('price.vat')))]),_c('div',[_c('Price',{attrs:{"value":order.vat_amount}})],1)]),_c('div',{staticClass:"AmountSummary__line"},[_c('div',[_vm._v(_vm._s(_vm.$t('price.total')))]),_c('div',[_c('Price',{attrs:{"value":order.amount}})],1)])]),(
                order.account_type === _vm.AccountType.DEALER &&
                  order.address &&
                  order.address.zip_code
              )?_c('div',{staticClass:"OrderScene__contactInfo"},[_c('h5',{staticClass:"ft-h5"},[_vm._v(_vm._s(_vm.$t('order.invoice_address')))]),_c('ContactInfo',{attrs:{"address":order.address,"name":order.address.name,"email":order.address.email_address,"forceWrap":""}})],1):_vm._e()])],1):_vm._e()],1)]},proxy:true},{key:"skeleton",fn:function(){return [_c('div',{staticClass:"OrderScene__skeleton"},[_c('div',{staticClass:"OrderScene__skeletonPagebar"},[_c('ButtonSkeleton')],1),_c('div',{staticClass:"OrderScene__skeletonOrderHead"},[_c('SkeletonGraphic',{attrs:{"width":230,"height":30}}),_c('div',{staticClass:"OrderScene__skeletonOrderHeadContent"},[_c('div',{staticClass:"OrderScene__skeletonTimeLine"},[_c('TimelineSkeleton',{attrs:{"nodes":3}})],1),_c('DetailsSkeleton',{staticClass:"OrderScene__skeletonDetailsSkeleton",attrs:{"items":6}})],1)],1),(!_vm.isMobileSize)?_c('div',{staticClass:"OrderScene__skeletonOrders"},[_c('TableSkeleton',{attrs:{"rows":2}})],1):_vm._e(),(!_vm.isMobileSize)?_c('div',{staticClass:"OrderScene__skeletonSummary"},[_c('div',{staticClass:"OrderScene__skeletonSummaryLabels"},[_c('SkeletonGraphic',{attrs:{"width":66,"height":22}}),_c('SkeletonGraphic',{attrs:{"width":52,"height":22}}),_c('SkeletonGraphic',{attrs:{"width":42,"height":22}})],1),_c('div',{staticClass:"OrderScene__skeletonSummaryValues"},[_c('SkeletonGraphic',{attrs:{"width":73,"height":22}}),_c('SkeletonGraphic',{attrs:{"width":55,"height":22}}),_c('SkeletonGraphic',{attrs:{"width":73,"height":22}})],1)]):_vm._e()])]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }