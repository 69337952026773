<template>
  <div v-if="order.notify_mobile || order.notify_email" class="ContactCard">
    <h4 class="ContactCard__lable ft-p2">Kontaktoplysninger</h4>
    <div class="ContactCard__container">
      <div v-if="order.notify_email" class="ContactCard__mail ft-p1">
        <i class="ContactCard__icon fal fa-envelope " />
        <span>{{ order.notify_email }}</span>
      </div>
      <div v-if="order.notify_mobile" class="ContactCard__phone ft-p1">
        <i class="ContactCard__icon fal fa-mobile" />
        <span>{{ order.notify_mobile }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactCard',

  computed: {
    order() {
      return this.orderContainer.order;
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactCard {
  display: flex;
  flex-direction: column;

  background-color: $color-prim-grey-lighter;
  padding: 20px;
  border-radius: $border-radius;
  width: 100%;

  .ContactCard__lable {
    color: #878787;
  }

  .ContactCard__container {
    display: flex;

    flex-wrap: wrap;
    gap: 20px;
  }

  .ContactCard__mail,
  .ContactCard__phone {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
  }

  .ContactCard__icon {
    font-size: 20px;
  }
}
</style>
