<template>
  <DeliveryExtension v-slot="{ status, branch, availableFrom, isClickAndCollect }">
    <div
      :class="[
        'PickupBanner',
        isMobileSize && 'PickupBanner--mobile',
        !branch && 'PickupBanner--spacing',
      ]"
    >
      <div class="PickupBanner__content">
        <div class="PickupBanner__header">
          <div v-if="status === PickupStatus.PICKED_UP" class="PickupBanner__title">
            {{ $t('order.delivery.pickup.collected') }}
          </div>
          <div
            v-else-if="status === PickupStatus.NOT_READY"
            class="PickupBanner__title PickupBanner__title--time "
          >
            {{ $t('order.delivery.pickup.available_from') }}:
            <Time
              class="PickupBanner__pickupDate"
              v-if="availableFrom"
              :datetime="availableFrom"
              :format="availableFrom.indexOf('00:00:0') !== -1 ? 'LL' : 'LLLL'"
            />
          </div>
          <div v-else-if="status === PickupStatus.READY" class="PickupBanner__title">
            {{ $t('order.delivery.pickup.ready') }}
          </div>
          <WorkshopTime />
          <BranchCardSmall
            v-if="branch"
            :branch="branch"
            :hideOpeningHours="status === PickupStatus.PICKED_UP"
            :date="availableFrom"
            class="PickupBanner__branch"
          />
        </div>
        <div class="PickupBanner__progressBarContainer">
          <PickupTimeline class="PickupBanner__progressBar" />
        </div>

        <div v-if="isClickAndCollect" class="PickupBanner__deliveryImage">
          <ImageComponent
            src="//cdn.thg.dk/dat/dom/img_misc/icon_delivery_click_and_collect_trim@2x.png"
            :alt="orderContainer.order.delivery.name"
          />
        </div>
      </div>
    </div>
  </DeliveryExtension>
</template>

<script>
import dayjs from 'dayjs';
import ImageComponent from '@components/ImageComponent';
import Time from '@components/Time';
import WorkshopTime from './components/WorkshopTime';
import { BranchCardSmall } from '@components/Cards';
import PickupTimeline from './components/PickupTimeline';
import { OrderPickupStatus as PickupStatus } from '@types/Order';
import { DeliveryExtension } from '@scenes/SelfserviceScene/containers/OrderContainer';
export default {
  name: 'PickupBanner',

  components: {
    ImageComponent,
    Time,
    WorkshopTime,
    BranchCardSmall,
    PickupTimeline,
    DeliveryExtension,
  },

  enums: {
    PickupStatus,
  },

  computed: {
    isMobileSize() {
      return ['mobileS', 'mobileM', 'mobileL'].includes(this.$mq);
    },
  },

  methods: {
    toDate(ISOString) {
      return dayjs(ISOString).toDate();
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.PickupBanner {
  width: 100%;
  background-color: $color-white;
  margin-top: -20px;
  margin-bottom: 40px;
  border: 1px solid $color-prim-grey;
  border-radius: 6px;
  padding: 20px 30px;

  @include maxBreakpoint(500) {
    padding: 20px;
  }

  .PickupBanner__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 40px;
  }
  &--mobile .PickupBanner__content {
    gap: 10px;
  }

  .PickupBanner__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .PickupBanner__title {
    font-weight: bold;
    white-space: nowrap;
    font-size: 16px;
    margin-top: -2px;
    line-height: 1.3em;
    color: $color-prim-brand;
    @include maxBreakpoint($tablet-numeric) {
      font-size: 16px;
      white-space: normal;
    }
  }

  .PickupBanner__title--time {
    font-size: 16px;
    color: black;
    font-weight: normal;

    @include maxBreakpoint($tablet-numeric) {
      font-size: 14px;
      white-space: normal;
    }

    time {
      display: block;
      font-weight: bold;
      color: $color-prim-brand;
    }
  }

  .PickupBanner__branch {
    min-width: 250px;
  }

  .PickupBanner__deliveryImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 75px;

    @include maxBreakpoint($mobileL-numeric) {
      top: auto;
      bottom: 0;
    }

    @include maxBreakpoint($tablet-numeric) {
      width: 60px;
    }

    @media print {
      top: 0;
    }
  }

  .PickupBanner__line {
    display: flex;
    align-self: center;
    width: 90%;
    border-bottom: 1px solid $color-prim-grey;
  }

  &--mobile .PickupBanner__line {
    display: none;
  }

  .PickupBanner__progressBarContainer {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    @include minBreakpoint($mobileL-numeric) {
      &::before {
        content: '';
        position: absolute;
        left: 5%;
        right: 5%;
        top: -20px;
        height: 1px;
        background-color: $color-prim-grey;
      }
    }
  }

  .PickupBanner__progressBar {
    max-width: 740px;
    width: 100%;
  }

  .PickupBanner__image {
    width: 100px;
  }
}
</style>
