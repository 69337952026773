<template>
  <ResizeObserver v-slot="{ width }">
    <StepProgressBar
      :alignment="width > 400 ? StepAlignment.CENTER : StepAlignment.VERTICAL"
      :size="width > 400 ? StepSize.MEDIUM : StepSize.SMALL"
      :completeSteps="completeStepIds"
      numbered
    >
      <Step v-for="step in visibleStepsInner" :key="step.id" :id="step.id" :title="step.title" />
    </StepProgressBar>
  </ResizeObserver>
</template>

<script>
import ResizeObserver from '@components/ResizeObserver';
import { StepProgressBar, Step, StepAlignment, StepSize } from '@components/StepProgressBar';

export default {
  name: 'PickupTimeline',

  components: {
    ResizeObserver,
    StepProgressBar,
    Step,
  },

  props: {
    freezeFirst: {
      // TODO: Remove when timeline is handled by API
      type: Boolean,
      default: false,
    },
  },

  enums: {
    StepAlignment,
    StepSize,
  },

  data() {
    return {
      steps: [
        {
          typeId: 0,
          step: { id: 'received_step', title: this.$t('order.delivery.pickup.status.received') },
        },
        {
          typeId: 5,
          showOn: [5],
          step: { id: 'ready_step', title: this.$t('order.delivery.pickup.status.ready') },
        },
        {
          typeId: 7,
          showOn: [6, 7],
          step: {
            id: 'being_picked_step',
            title: this.$t('order.delivery.pickup.status.being_picked'),
          },
        },
        {
          typeId: 6,
          showOn: [6],
          step: { id: 'packed_step', title: this.$t('order.delivery.pickup.status.packed') },
        },
        {
          typeId: 2,
          step: { id: 'collected_step', title: this.$t('order.delivery.pickup.status.collected') },
        },
      ],
    };
  },

  computed: {
    lastCompleteStepIndex() {
      if (this.freezeFirst) return 0; // Ignore event types when we are waiting on a booking/service to be completed.

      const lastCompleteStep = [...this.steps].reverse().findIndex(step => {
        return this.orderContainer.eventTypes.includes(step.typeId);
      });

      return this.steps.length - 1 - lastCompleteStep;
    },
    completeStepIds() {
      const completeSteps = this.steps.filter((_, i) => i <= this.lastCompleteStepIndex);
      return completeSteps.map(x => x.step.id);
    },
    visibleSteps() {
      const visibleSteps = this.steps.filter((step, index) => {
        if (step.showOn !== undefined) {
          return step.showOn.some(typeId => {
            return (
              this.orderContainer.eventTypes.indexOf(typeId) !== -1 ||
              index > this.lastCompleteStepIndex
            );
          });
        }
        return true;
      });
      return visibleSteps.length > 1 ? visibleSteps : this.steps;
    },
    visibleStepsInner() {
      return this.visibleSteps.map(x => x.step);
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>
