<template>
  <div v-if="booking && booking.service_start" class="WorkshopTime">
    <div>
      <div class="WorkshopTime__header">
        <h4 class="WorkshopTime__title ft-p2">
          <i class="WorkshopTime__icon far fa-clock" /> {{ $t('order.booking.workshop_time') }}
        </h4>
      </div>
      <span v-if="booking.service_name" class="WorkshopTime__name ">
        {{ booking.service_name }}
      </span>
      <div class="WorkshopTime__content">
        <Time :datetime="booking.service_start" format="L LT" />
        <div v-if="booking.service_end">
          <span>
            ({{ $t('order.booking.finish').toLowerCase() }}
            <Time :datetime="booking.service_end" :format="isSameDate ? 'LT' : 'L LT'" />)
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Time from '@components/Time';

export default {
  name: 'WorkshopTime',

  components: { Time },

  computed: {
    booking() {
      if (this.orderContainer.order.bookings) {
        return this.orderContainer.order.bookings[0];
      }
      return null;
    },
    isSameDate() {
      const serviceStartDateString = this.booking.service_start.split('T')[0];
      const serviceEndDateString = this.booking.service_end.split('T')[0];
      return serviceStartDateString === serviceEndDateString;
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.WorkshopTime {
  .WorkshopTime__header {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .WorkshopTime__title {
    color: #878787;
  }

  .WorkshopTime__content {
    display: flex;
    gap: 5px;
    font-size: 16px;
    font-weight: bold;

    @include maxBreakpoint($tablet-numeric) {
      font-size: 14px;
    }

    @include maxBreakpoint($mobileL-numeric) {
      flex-direction: column;
      gap: 0;
    }
  }
}
</style>
