<template>
  <ResizeObserver v-slot="{ width }">
    <div v-if="width > 700" class="OrderLines">
      <Table :items="orderContainer.lines" itemKey="line_id" :alignment="ColumnAlignment.RIGHT">
        <TableColumn
          :header="$t('order.line.header')"
          :alignment="ColumnAlignment.LEFT"
          v-slot="{ item }"
        >
          <OrderLineCard
            :line="item"
            :showVehicleId="orderContainer.accountType === AccountType.DEALER"
          >
            <AvailabilityStatus class="OrderLineCard__availability" :line="item" />
          </OrderLineCard>
        </TableColumn>
        <TableColumn :header="priceHeader" v-slot="{ item }">
          <Price v-if="item.product.item_type !== 'placeholder'" :value="item.product.price" />
        </TableColumn>
        <TableColumn :header="$t('quantity')" value="quantity" />
        <TableColumn
          v-if="includeNetPrice"
          :header="$t('price.net')"
          value="product.net_price"
          :format="ColumnFormat.PRICE"
        />
        <TableColumn v-if="includeDiscountRate" :header="$t('price.discount')" v-slot="{ item }">
          {{ item.product.discount_rate && getPercentage(item.product.discount_rate) }}
        </TableColumn>
        <TableColumn :header="$t('price.amount')" strong v-slot="{ item }">
          <Price v-if="item.product.item_type !== 'placeholder'" :value="item.total" />
        </TableColumn>
      </Table>
    </div>
    <div v-else class="OrderLines">
      <ul class="OrderLines__cards">
        <OrderLineCard
          v-for="(line, index) in orderContainer.lines"
          :key="index"
          tag="li"
          :line="line"
          class="OrderLines__card"
        >
          <AvailabilityStatus class="OrderLineCard__availability" :line="line" />
          <Details :data="details(line)" :columnSize="Sizes.TINY" smallHeader />
        </OrderLineCard>
      </ul>
    </div>
  </ResizeObserver>
</template>

<script>
import { AccountType } from '@types/Account';
import ResizeObserver from '@components/ResizeObserver';
import { Table, TableColumn, ColumnAlignment, ColumnFormat } from '@components/Table';
import Price from '@components/Price';
import Details, {
  Formats as DetailFormats,
  Sizes,
} from '@scenes/SelfserviceScene/components/Details';
import OrderLineCard from '@components/OrderLineCard';
import AvailabilityStatus from '@scenes/SelfserviceScene/scenes/OrderScene/components/AvailabilityStatus';

export default {
  name: 'OrderLines',

  components: {
    ResizeObserver,
    Table,
    TableColumn,
    Price,
    Details,
    OrderLineCard,
    AvailabilityStatus,
  },

  enums: {
    AccountType,
    ColumnAlignment,
    ColumnFormat,
    Sizes,
  },

  computed: {
    priceHeader() {
      return this.orderContainer.accountType === AccountType.DEALER
        ? this.$t('price.gross')
        : this.$t('price.item');
    },
    includeNetPrice() {
      return this.orderContainer.accountType === AccountType.DEALER;
    },
    includeDiscountRate() {
      return this.orderContainer.accountType === AccountType.PRIVATE;
    },
    details() {
      return line => {
        return [
          {
            header: this.priceHeader,
            label: line.product.item_type !== 'placeholder' ? line.product.price : null,
            format: DetailFormats.PRICE,
            light: true,
          },
          { header: this.$t('quantity'), label: line.quantity, light: true },
          this.includeNetPrice && {
            header: this.$t('price.net'),
            label: line.product.item_type !== 'placeholder' ? line.product.net_price : null,
            format: DetailFormats.PRICE,
            light: true,
          },
          this.includeDiscountRate &&
            line.product.discount_rate && {
              header: this.$t('price.discount'),
              label: this.getPercentage(line.product.discount_rate),
              light: true,
            },
          {
            header: this.$t('price.amount'),
            label: line.product.item_type !== 'placeholder' ? line.total : null,
            format: DetailFormats.PRICE,
          },
        ];
      };
    },
  },

  methods: {
    getPercentage(number) {
      return Math.round(number * 100) + '%';
    },
  },

  inject: {
    orderContainer: {
      default() {
        console.error('Missing OrderContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderLines {
  .OrderLines__cards {
    display: flex;
    flex-direction: column;
  }

  .OrderLines__card {
    padding: 20px 0;
    border-bottom: 1px solid $color-prim-grey;
  }
}
</style>
